import React from 'react';
import strftime from 'strftime';
import ApiRequest from '../libs/ApiRequest';
import { store, line_items_key } from '../constants/globals';
import { InvoiceApiResp, isErrorResponse, CalcLineItem, ArrItem, ChargeCalc } from '../types/json_types';
import { processInvoiceJSON } from '../helpers/invoice_helpers';
import { Typography, FormHelperText, FormControlLabel, Checkbox, Button, Link } from '@material-ui/core';
import { centsToDollarString, isPresent } from '../util/util';
import { resDetails, arrivingTxt } from '../helpers/reservation_helpers';
import clsx from 'clsx';
import StripeFormStart from './stripe_form/StripeFormStart';
import Invoice from '../models/Invoice';

// bump
const renderItem = (ai: ArrItem, key: string, index: number): JSX.Element => {
  const className = clsx('d-flex justify-content-between', { 'pl-2': index > 0 });
  const q = ai.quantity;
  return (
    <div className={className} key={key}>
      <div>
        {ai.label} {q > 1 ? `x${q}` : ''}
      </div>
      <div>{centsToDollarString(ai.cents)}</div>
    </div>
  );
};

const renderRow = (li: CalcLineItem): JSX.Element => {
  return (
    <div key={li.key} className="mb-2" style={{ borderBottom: '1px solid #e4e4e4' }}>
      {li.arr.map((ai, i) => renderItem(ai, li.key + '_' + i, i))}
    </div>
  );
};

const renderCharge = (c: ChargeCalc): JSX.Element => {
  return (
    <div key={c.key} className="mb-2 d-flex justify-content-between" style={{ borderBottom: '1px solid #e4e4e4' }}>
      <div>{c.label}</div>
      <div>{centsToDollarString(c.price_cents)}</div>
    </div>
  );
};

const handlePayWithCashChange = (val: boolean): void => {
  store.setState('pay_w_cash', val);
};

const payWithCash = (invoice_id: number, pay_w_cash_working: boolean): void => {
  if (pay_w_cash_working) {
    return;
  }

  store.setState('pay_w_cash_working', true);

  const req = new ApiRequest(
    '/store_api/pay_w_cash.json',
    'POST',
    {
      onSuccess: (resp: unknown): void => {
        const json = resp as InvoiceApiResp;

        const invoice = processInvoiceJSON(json.base.invoice, json.base.reservation);

        window.App.calcResp = json.calc;

        localStorage.removeItem(line_items_key);

        store.getStateObj().pay_w_cash_working = false;
        store.setState('invoice', invoice);
      },
      onError: (json: unknown): void => {
        if (isErrorResponse(json)) {
          alert(`The following errors occurred: ${json.errors.join(' & ')}`);
        } else {
          alert('an error occurred while trying to make the purchase');
        }

        store.setState('pay_w_cash_working', false);
      },
    },
    {
      invoice_id,
    },
  );

  req.perform();
};

const payWithCardInstead = (invoice_id: number, pay_w_cash_working: boolean): void => {
  if (pay_w_cash_working) {
    return;
  }

  store.setState('pay_w_cash_working', true);

  const req = new ApiRequest(
    '/store_api/pay_w_card_instead.json',
    'POST',
    {
      onSuccess: (resp: unknown): void => {
        const json = resp as InvoiceApiResp;

        const invoice = processInvoiceJSON(json.base.invoice, json.base.reservation);

        window.App.calcResp = json.calc;

        localStorage.removeItem(line_items_key);

        const state = store.getStateObj();

        state.pay_w_cash_working = false;
        state.pay_w_cash = false;

        store.setState('invoice', invoice);
      },
      onError: (json: unknown): void => {
        if (isErrorResponse(json)) {
          alert(`The following errors occurred: ${json.errors.join(' & ')}`);
        } else {
          alert('an error occurred while trying to make the purchase');
        }

        store.setState('pay_w_cash_working', false);
      },
    },
    {
      invoice_id,
    },
  );

  req.perform();
};

const paySection = (
  invoice: Invoice,
  final_cents: number,
  pay_w_cash: boolean,
  pay_w_cash_working: boolean,
): JSX.Element => {
  if (invoice.status === 'PAID') {
    const cents = invoice.pay_w_cash ? invoice.total_cents : invoice.final_charge_cents;
    return (
      <React.Fragment>
        <Typography className="text-align-center">
          Thank you for you payment! You paid {cents ? centsToDollarString(cents) : '$??.?'}.
        </Typography>

        <Typography className="text-align-center mt-2">
          We&apos;ve sent a receipt to your email address, but please feel free to save a copy of this screen for your
          records as well.
        </Typography>
      </React.Fragment>
    );
  } else if (invoice.status === 'CASH_PAYMENT_PENDING') {
    const cents = invoice.total_cents;
    return (
      <React.Fragment>
        <Typography className="text-align-center">
          Thank you for scheduling to pay with cash or check! Your total due is{' '}
          {cents ? centsToDollarString(cents) : '$??.?'}. We will be in touch!
        </Typography>

        <Typography className="text-align-center mt-2">
          We&apos;ve sent a receipt to your email address, but please feel free to save a copy of this screen for your
          records as well.
        </Typography>

        <Button
          style={{ width: '100%' }}
          onClick={(): void => payWithCardInstead(invoice.id, pay_w_cash_working)}
          size="large"
          variant="contained"
          color="primary"
          disableElevation
          disabled={pay_w_cash_working}
        >
          Pay with credit card instead.
        </Button>
      </React.Fragment>
    );
  } else if (pay_w_cash) {
    return (
      <div>
        <div className="mt-2 mb-2">
          <Typography>
            <div className="mt-3">
              Don&apos;t want to purchase the Accidental Damage Insurance? No problem. If you would prefer to pay a
              fully refundable security deposit ($500) rather than purchase the insurance that is listed on this
              invoice, simply let us know and we will send you a revised invoice.
            </div>
          </Typography>

          <Button
            style={{ width: '100%' }}
            onClick={(): void => payWithCash(invoice.id, pay_w_cash_working)}
            size="large"
            variant="contained"
            color="primary"
            disableElevation
            disabled={pay_w_cash_working}
          >
            Pay with cash or check
          </Button>
        </div>

        <Typography className="my-2 mt-3">
          By submitting this payment, you agree to our{' '}
          <Link href="http://dormsdirect.com/tos.html" target="_blank">
            Terms of Service
          </Link>{' '}
          and our{' '}
          <Link href="http://dormsdirect.com/returns.html" target="_blank">
            Refund Policy
          </Link>
        </Typography>

        <FormHelperText>
          There is no fee for paying by check or cash. However, your FULL PAYMENT MUST BE RECEIVED PRIOR to us being
          able to deliver your items. By choosing this option, you understand that your items are NOT guaranteed to be
          delivered prior to the start of classes.
        </FormHelperText>
        <FormControlLabel
          control={
            <Checkbox checked={pay_w_cash} onChange={(): void => handlePayWithCashChange(false)} color="primary" />
          }
          label="Pay with cash or check"
        />
      </div>
    );
  } else {
    return (
      <React.Fragment>
        <Typography>
          <div className="mt-3">
            Don&apos;t want to purchase the Accidental Damage Insurance? No problem. If you would prefer to pay a fully
            refundable security deposit ($500) rather than purchase the insurance that is listed on this invoice, simply
            let us know and we will send you a revised invoice.
          </div>
        </Typography>

        <StripeFormStart total={final_cents} />

        <Typography className="my-2 mt-3">
          By submitting this payment, you agree to our{' '}
          <Link href="http://dormsdirect.com/tos.html" target="_blank">
            Terms of Service
          </Link>{' '}
          and our{' '}
          <Link href="http://dormsdirect.com/returns.html" target="_blank">
            Refund Policy
          </Link>
        </Typography>

        <FormHelperText>
          To help cover the cost of card acceptance, we pass on a small processing fee. This fee is not more than the
          cost of accepting these cards. There is no fee for paying by check or cash. However, your FULL PAYMENT MUST BE
          RECEIVED PRIOR to us being able to deliver your items. By choosing this option, you understand that your items
          are NOT guaranteed to be delivered prior to the start of classes.
        </FormHelperText>

        <FormControlLabel
          className="mb-3"
          control={
            <Checkbox checked={pay_w_cash} onChange={(): void => handlePayWithCashChange(true)} color="primary" />
          }
          label="Pay with cash or check"
        />
      </React.Fragment>
    );
  }
};

const InvoicePage = (): JSX.Element => {
  const invoice = store.useState('invoice');
  const pay_w_cash = store.useState('pay_w_cash');
  const pay_w_cash_working = store.useState('pay_w_cash_working');

  if (invoice) {
    const res = invoice.reservation;
    const { studentTxt, parentTxt, roomTxt } = resDetails(res);

    const created_at_str = strftime('%b %e %l:%M %p', invoice.created_at);
    const { line_items, charges, subtotal, tax, total } = window.App.calcResp!;
    const { fee, final_charge_cents } = window.App.calcResp!;

    const paid = invoice.status === 'PAID';

    return (
      <div className="d-flex">
        <div className="p-1 flex-grow-1" style={{ maxWidth: 600, margin: 'auto' }}>
          <Typography className="text-align-center" variant="h6">
            Your Invoice ID: {invoice.token}
          </Typography>

          <Typography className="text-align-center mb-1" variant="caption" display="block">
            Reserved on: {created_at_str}
          </Typography>

          <div className="p-1">
            <div>
              <b>Student: </b>
              {studentTxt}
              {roomTxt?.trim() ? ` - ${roomTxt}` : null}
            </div>
            <div>
              <b>Parent: </b>
              {parentTxt}
            </div>
            <div>
              <b>Expected student arrival date: </b>
              {arrivingTxt(res.arrival_date_at)}
            </div>

            {isPresent(res.special_instructions) ? (
              <div>
                <b>Special Instructions: </b>
                {res.special_instructions}
              </div>
            ) : null}
          </div>

          <div
            style={{ background: '#fafafa', border: '1px solid #bfbfbf', borderRadius: 5 }}
            className="py-2 px-1 mb-3 mt-3"
          >
            {line_items.map(renderRow)}
            {charges.map(renderCharge)}
            <div className="d-flex justify-content-between">
              <div>Subtotal:</div> <div>{centsToDollarString(subtotal)}</div>
            </div>
            <div className="d-flex justify-content-between">
              <div>VA Sales Tax (6%): </div>
              <div>{centsToDollarString(tax)}</div>
            </div>
            <div className="d-flex justify-content-between" style={{ fontWeight: paid ? 'normal' : 'bold' }}>
              <div>Total: </div>
              <div>{centsToDollarString(total)}</div>
            </div>
            {pay_w_cash ? null : (
              <React.Fragment>
                <div className="d-flex justify-content-between">
                  <div>Card Processing Fee: </div>
                  <div>{centsToDollarString(fee)}</div>
                </div>
                <div className="d-flex justify-content-between" style={{ fontWeight: 'bold' }}>
                  <div>Final Total: </div>
                  <div>{centsToDollarString(final_charge_cents)}</div>
                </div>
              </React.Fragment>
            )}
          </div>

          {invoice.status === 'CANCELLED' ? (
            <Typography>
              This invoice has been cancelled. If you feel this is in error, please contact support@dormsdirect.com.
            </Typography>
          ) : (
            paySection(invoice, final_charge_cents, pay_w_cash, pay_w_cash_working)
          )}
        </div>
      </div>
    );
  } else {
    const req = new ApiRequest(`/store_api/get_invoice?token=${window.App.invoice_token}`, 'GET', {
      onSuccess: (resp: unknown): void => {
        const json = resp as InvoiceApiResp;
        const invoice = processInvoiceJSON(json.base.invoice, json.base.reservation);
        window.App.calcResp = json.calc;
        store.getStateObj().pay_w_cash = invoice.pay_w_cash;
        store.setState('invoice', invoice);
      },
      onError: (json: unknown): void => {
        if (isErrorResponse(json)) {
          alert(json.errors.join('&'));
        } else {
          alert(`couldnt find invoice with token ${window.App.invoice_token}`);
        }
      },
    });
    req.perform();

    return <div></div>;
  }
};

export default InvoicePage;
