import ApplicationModel from '../models/ApplicationModel';
import { then } from './util';

export const findModelById = function <T extends ApplicationModel>(arr: Array<T>, id: number): T {
  const length = arr.length;

  for (let index = 0; index < length; index++) {
    if (arr[index].id === id) return arr[index];
  }

  throw new Error(`Couldn't find ${arr[0].getClassName()} by id ${id}`);
};

export const findModelByAttr = function <T extends ApplicationModel, Key extends keyof T>(
  arr: Array<T>,
  attr: Key,
  value: T[Key],
): T {
  const length = arr.length;

  for (let index = 0; index < length; index++) {
    if (arr[index][attr] === value) return arr[index];
  }

  throw new Error(`Couldn't find ${arr[0].getClassName()} by attr ${attr.toString()} & val ${value}`);
};

export const filterModelsByAttr = function <T extends ApplicationModel, Key extends keyof T>(
  arr: Array<T>,
  attr: Key,
  value: T[Key],
): T[] {
  const length = arr.length;

  const foundItems: Array<T> = [];
  let item: T;

  for (let index = 0; index < length; index++) {
    item = arr[index];
    if (item[attr] === value) {
      foundItems.push(item);
    }
  }

  return foundItems;
};

export const departureDateStrForSchool = (school_id: number): string => {
  return then(school_id, (school_id) => {
    switch (school_id) {
      case 1:
        return '2023-05-23';
      case 2:
        return '2023-05-02';
      case 3:
        return '2023-05-07';
      default:
        throw new Error(
          `school_id should be 1,2 or 3, instead it was ${school_id}; something has gone horribly, utterly wrong`,
        );
    }
  });
};