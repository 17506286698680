import React from 'react';
import { Typography } from '@material-ui/core';

const Success = (): JSX.Element => {
  return (
    <div className="p-1" style={{ maxWidth: 600, margin: 'auto' }}>
      <Typography>
        Success! After we have verified your reservation, we will send you an invoice within the next few days so keep
        an eye on your email. We&apos;ll be in touch!
      </Typography>
    </div>
  );
};

export default Success;
