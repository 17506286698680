import { Button } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { Alert } from '@material-ui/lab';
import React from 'react';
import strftime from 'strftime';
import { store } from '../constants/globals';
import { processInvoiceJSON, getStateForDepartureUpdate } from '../helpers/invoice_helpers';
import { resDetails } from '../helpers/reservation_helpers';
import ApiRequest from '../libs/ApiRequest';
import { InvoiceApiResp, isErrorResponse } from '../types/json_types';
import { getDateFromDateStr, getDateStr, isPresent } from '../util/util';
import AppDatePicker from './util/AppDatePicker';

const { setState, setPartialState, getNonNullState, useNonNullState: useDepartureState } = store.getHelpers(
  'update_departure_page',
);

const clickUpdate = (): void => {
  const { departure_date, invoice_token } = getNonNullState();

  const req = new ApiRequest(
    `/store_api/reservations/${invoice_token}.json`,
    'PATCH',
    {
      onSuccess: (resp: unknown): void => {
        const json = resp as InvoiceApiResp;
        const invoice = processInvoiceJSON(json.base.invoice, json.base.reservation);

        setPartialState({ invoice, success: true });
      },
      onError: (json: unknown): void => {
        if (isErrorResponse(json)) {
          alert(json.errors.join('&'));
        } else {
          alert(`couldnt find invoice with token ${invoice_token}`);
        }
      },
    },
    { reservation: { departure_date } },
  );
  req.perform();
};

const renderSuccess = (dateStr: string): JSX.Element => {
  const d = getDateFromDateStr(dateStr);

  return (
    <div className="mt-3">
      <Alert severity="success">
        Success! You&apos;ve set your room vacancy date to {strftime('%B %o %Y', d)}
        <div className="mt-1">Thanks for letting us know!</div>
      </Alert>
    </div>
  );
};

export const UpdateDeparture = (): JSX.Element => {
  const state = useDepartureState();

  const { invoice, invoice_token, departure_date, working, success } = state;

  const date = getDateFromDateStr(departure_date);

  if (invoice) {
    const res = invoice.reservation;

    const { studentTxt, parentTxt, roomTxt } = resDetails(res);

    return (
      <div className="d-flex flex-column" style={{ maxWidth: 500, margin: 'auto' }}>
        <Typography variant="h6">Update your room vacancy date</Typography>
        <div className="p-1">
          <div>
            <b>Student: </b>
            {studentTxt}
          </div>
          {isPresent(roomTxt) && (
            <div>
              <b>Room: </b>
              {roomTxt}
            </div>
          )}
          <div>
            <b>Parent: </b>
            {parentTxt}
          </div>
        </div>

        <div className="my-2">
          <AppDatePicker
            date={date}
            style={{ width: '100%' }}
            onChangeDate={(d): void => {
              const str = getDateStr(d ? d : new Date());

              setPartialState({ departure_date: str });
            }}
            label="What day will your room be vacant?"
          />
        </div>

        {success ? renderSuccess(departure_date) : null}

        <div className="mt-3">
          <Button variant="outlined" color="primary" onClick={clickUpdate} disabled={working}>
            Update Room Vacancy Date
          </Button>
        </div>
      </div>
    );
  } else {
    const req = new ApiRequest(`/store_api/get_invoice?token=${invoice_token}`, 'GET', {
      onSuccess: (resp: unknown): void => {
        const json = resp as InvoiceApiResp;
        const invoice = processInvoiceJSON(json.base.invoice, json.base.reservation);
        const state = getStateForDepartureUpdate(invoice_token, invoice);

        setState(state);
      },
      onError: (json: unknown): void => {
        if (isErrorResponse(json)) {
          alert(json.errors.join('&'));
        } else {
          alert(`couldnt find invoice with token ${invoice_token}`);
        }
      },
    });
    req.perform();

    return <div></div>;
  }
};
