import Product from '../models/Product.js';
import sortBy from 'lodash/sortBy.js';
import isUndefined from 'lodash/isUndefined.js';

export const centsToDollarString = function (cents: number): string {
  const dollars = cents / 100;
  return dollars.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
};

export const sortProductsByShorts = (products: Product[], shorts: string[]): Product[] => {
  return sortBy(products, (p) => shorts.indexOf(p.short));
};

export const spliceFromArrayIfPresent = <T>(arr: Array<T>, item: T): void => {
  const index = arr.indexOf(item);

  if (index > -1) {
    arr.splice(index, 1);
  }
};

export const spliceItemsFromArray = <T>(arr: Array<T>, items: T[]): void => {
  for (let j = 0; j < items.length; j++) {
    const item = items[j];

    const index = arr.indexOf(item);

    if (index > -1) {
      arr.splice(index, 1);
    }
  }
};

export const isDefined = function (val: unknown): boolean {
  return !isUndefined(val);
};

export const isBlank = function (string?: string): boolean {
  return !isPresent(string);
};

export function isString(x: unknown): x is string {
  return typeof x === 'string';
}

export const presence = (str?: string | null): string | null => {
  if (isPresent(str)) {
    return str as string;
  }

  return null;
};

export const isPresent = function (str?: string | null): boolean {
  if (!str) {
    return false;
  }

  return typeof str === 'string' && str.trim() !== '';
};

export const arraysMatch = <T>(arr1: T[], arr2: T[]): boolean => {
  // Check if the arrays are the same length
  if (arr1.length !== arr2.length) return false;

  // Check if all items exist and are in the same order
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) return false;
  }

  // Otherwise, return true
  return true;
};

export const zeroPad = (n: number): string => {
  const zero = n < 10 ? '0' : '';
  return `${zero}${n}`;
};

/**
 * get YYYY-MM-DD formatted date string
 *
 * @doctest
 * ```js
 * const d = new Date();
 * d.setFullYear(2018);
 * // 1 === feb in JS world
 * d.setMonth(1);
 * d.setDate(21);
 * t.is(getDateStr(d), '2018-02-21');
 * ```
 */
export const getDateStr = (d: Date): string => {
  const year = d.getFullYear();
  const month = zeroPad(d.getMonth() + 1);
  const day = zeroPad(d.getDate());

  return `${year}-${month}-${day}`;
};

/**
 * get date from YYYY-MM-DD formatted date string
 *
 * @doctest
 * ```js
 * const d = getDateFromDateStr("2020-02-19");
 * t.is(d.getFullYear(), 2020);
 * t.is(d.getMonth(), 1);
 * t.is(d.getDate(), 19);
 * ```
 */
export const getDateFromDateStr = (str: string): Date => {
  const [year, month, day] = str.split('-').map((n) => parseInt(n));
  const date = new Date();
  date.setFullYear(year);
  date.setMonth(month - 1);
  date.setDate(day);
  date.setHours(12);

  return date;
};

/**
 * transform a value via callback
 *
 * @doctest
 * ```js
 * t.is(then(1, (n) => n + 1), 2)
 * ```
 */
export const then = <A, B>(a: A, cb: (a: A) => B): B => {
  return cb(a);
};

export const round2 = (num: number): number => {
  return Math.round((num + Number.EPSILON) * 100) / 100;
};

export const attemptParseInt = function (num: string): number | null {
  const blah = parseInt(num);

  return blah ?? null;
};

export const unwrap = <T>(value: T | undefined | null, errorMessage?: string): T => {
  if (value == null) {
    throw new Error(errorMessage == null ? 'Value should be here!' : errorMessage);
  } else {
    return value;
  }
};
