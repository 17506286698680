import Reservation from '../models/Reservation';
import { isPresent } from '../util/util';
import strftime from 'strftime';

export const resDetails = (r: Reservation): { studentTxt: string; parentTxt: string; roomTxt: string } => {
  const studentTxt = `${r.studentFullName()} - ${r.student_email} - ${r.student_phone} - ${r.year} @ ${
    r.getSchool().name
  }`;

  let roomTxt = 'unknown';

  const building = r.getBuilding();
  if (building) {
    roomTxt = building.shown_name;

    if (isPresent(r.room_number)) {
      roomTxt += `, ${r.room_number}`;
    }

    if (isPresent(r.room_wing)) {
      roomTxt += ` ${r.room_wing}`;
    }
  }

  let parentTxt = `${r.parentFullName()} - ${r.parent_email}`;

  if (isPresent(r.parent_phone)) {
    parentTxt += ` - ${r.parent_phone}`;
  }

  return { studentTxt, parentTxt, roomTxt };
};

export const arrivingTxt = (arrival_date: Date | null): string => {
  if (arrival_date) {
    return strftime('%b %e', arrival_date);
  } else {
    return 'not sure';
  }
};
