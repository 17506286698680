import { createBrowserHistory } from 'history';

import { pathToRegexp } from 'path-to-regexp';

import { History, LocationState, UnregisterCallback, Location } from 'history';

export type RouteMap<T> = {
  [name: string]: (...params: string[]) => T;
};

const getParams = (execArray: RegExpExecArray): string[] => {
  const params = [];
  for (let i = 1; i < execArray.length; i++) {
    params.push(execArray[i]);
  }

  return params;
};

// TODO: force user to define error (path might not match) callback
class StateRouter<T> {
  private history: History<LocationState>;
  private stopListening?: UnregisterCallback;
  private routes: RouteMap<T>;
  private regexpMaps: Array<[RegExp, string]>;
  private handler: (state: T) => void;
  private badRouterHandler: (path: string) => T;

  constructor(routes: RouteMap<T>, handler: (state: T) => void, badRouteHandler: (path: string) => T) {
    this.routes = routes;
    this.regexpMaps = Object.keys(this.routes).map((r) => [pathToRegexp(r), r]);
    this.history = createBrowserHistory<LocationState>();
    this.listener = this.listener.bind(this);
    this.handler = handler;
    this.badRouterHandler = badRouteHandler;
  }

  start(): boolean {
    if (this.stopListening) {
      return false;
    }

    this.stopListening = this.history.listen(this.listener);
    return true;
  }

  stop(): boolean {
    if (this.stopListening) {
      this.stopListening();
      this.stopListening = undefined;
      return true;
    }

    return false;
  }

  go(path: string): void {
    this.history.push(path, 0);
  }

  stateFromPath(path: string): T {
    const match = this.matchPath(path);

    if (match) {
      const fn = this.routes[match[1]];

      const regExpExec = match[0];
      const params = regExpExec ? getParams(regExpExec) : [];
      return fn(...params);
    } else {
      return this.badRouterHandler(path);
    }
  }

  private listener(location: Location): void {
    this.callHandler(location.pathname);
  }

  private matchPath(path: string): [RegExpExecArray | null, string] | null {
    const arr = this.regexpMaps.find((m) => m[0].test(path));
    if (!arr) return null;

    const regexp = arr[0];
    const tuple: [RegExpExecArray | null, string] = [regexp.exec(path), arr[1]];

    return tuple;
  }

  private callHandler(path: string): void {
    const state = this.stateFromPath(path);

    if (state) {
      this.handler(state);
    } else {
      this.badRouterHandler(path);
    }
  }
}

export default StateRouter;
