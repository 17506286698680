import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Upgrade from '../models/Upgrade';
import { findModelById } from '../util/app_util';

export type UpgradeRadioGroupProps = {
  upgrades: Upgrade[];
  upgrade: Upgrade | null;
  onChange: (upgrade: Upgrade) => void;
};

const renderUpgrade = (u: Upgrade): JSX.Element => {
  return <FormControlLabel key={`up_${u.id}`} value={u.id} control={<Radio />} label={u.label} />;
};

export default function UpgradeRadioGroup(props: UpgradeRadioGroupProps): JSX.Element {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const upgrade = findModelById(props.upgrades, parseInt(event.target.value));
    props.onChange(upgrade);
  };

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">Mattress Size</FormLabel>
      <RadioGroup value={props.upgrade?.id || 'null'} onChange={handleChange}>
        {props.upgrades.map(renderUpgrade)}
      </RadioGroup>
    </FormControl>
  );
}
