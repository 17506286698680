import { StudentForm, ParentForm } from '../types/form_types';
import { FinalState } from '../types/state_types';

export const email_regexp = /^[^@\s]+@[^@\s]+$/i;

export const cntInts = (str: string): number => {
  return str.replace(/[^0-9]/g, '').length;
};

export const getDefaultStudentData = (): StudentForm => {
  if (window.App.studentDeets) {
    return window.App.studentDeets;
  } else {
    return {
      student_email: '',
      student_first_name: '',
      student_last_name: '',
      student_phone: '',
      building_id: 'null',
      room_number: '',
      room_wing: '',
      year: 'Freshman',
    };
  }
};

export const getDefaultParentData = (): ParentForm => {
  if (window.App.parentDeets) {
    return window.App.parentDeets;
  } else {
    return {
      parent_email: '',
      parent_first_name: '',
      parent_last_name: '',
      parent_phone: '',
    };
  }
};

export const formatPhoneNumber = (phoneNumberString: string): string | null => {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }

  return null;
};

export const getDefaultFinalState = (): FinalState => {
  return {
    arrival_date_at: null,
    arriving_prior: null,
    arrival_unknown: false,
    special_instructions: '',
    working: false,
  };
};
