/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { TextField } from '@material-ui/core';
import { SchoolYear } from '../../types/json_types';

export type CardType = 'credit' | 'debit';
type StudentYearSelectProps = {
  year: SchoolYear;
  onChange: {
    (e: React.ChangeEvent<any>): void;
    <T = string | React.ChangeEvent<any>>(field: T): T extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };
};

const SchoolYearSelect = (props: StudentYearSelectProps): JSX.Element => {
  return (
    <div className="d-flex justify-content-center align-items-center my-3" style={{ width: '100%' }}>
      <TextField
        style={{ width: '100%' }}
        id="year"
        name="year"
        select
        label="Year"
        value={props.year}
        onChange={props.onChange}
        SelectProps={{
          native: true,
        }}
        variant="outlined"
      >
        <option value="Freshman">Freshman</option>
        <option value="Sophmore">Sophmore</option>
        <option value="Junior">Junior</option>
        <option value="Senior">Senior</option>
        <option value="Graduate">Graduate</option>
      </TextField>
    </div>
  );
};

export default SchoolYearSelect;
