import { Store } from 'set-state-is-great';
import router from './router';
import { AppState } from '../types/state_types';
import Cart from '../models/Cart';

const cart = new Cart([]);

const appState: AppState = {
  colormode: localStorage.getItem('darkMode') ? 'dark' : 'light',
  cart_count: 0,
  cart_drawer_open: false,
  drawer_open: false,
  checked_upgrades: [],
  pay_w_cash: false,
  pay_w_cash_working: false,
};

const store = new Store<AppState>(appState);

export { store, router, cart };
