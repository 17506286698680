import React from 'react';
import { getModels } from '../constants/globals';
import { FormControl, InputLabel, Select } from '@material-ui/core';
import { store } from '../init_app/store';
import { findModelById } from '../util/app_util';

const { setState: setSchool, useStoreState: useSchool } = store.getHelpers('school');

const handleChange = (event: React.ChangeEvent<{ value: unknown }>): void => {
  const val = event.target.value as string;
  const school = val === 'null' ? null : findModelById(getModels('schools'), parseInt(val));

  if (school) {
    localStorage.setItem('school_id', school.id.toString());
    setSchool(school);
    store.setState('viewShown', 'Home');
  } else {
    setSchool(undefined);
  }
};

const PickSchool = (): JSX.Element => {
  const school = useSchool();
  const schools = getModels('schools');

  return (
    <div className="d-flex justify-content-center align-items-center" style={{ marginTop: 30, minWidth: 120 }}>
      <FormControl variant="outlined">
        <InputLabel htmlFor="school_select">Please select your school</InputLabel>
        <Select
          native
          value={school?.id || 'null'}
          onChange={handleChange}
          label="Please select your school"
          inputProps={{
            name: 'school_select',
            id: 'school_select',
          }}
        >
          <option value="null">Select School</option>
          {schools.map((s) => (
            <option key={`school_select_${s.id}`} value={s.id}>
              {s.name}
            </option>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default PickSchool;
