import React from 'react';
import { spliceFromArrayIfPresent } from '../util/util';
import { store } from '../constants/globals';
import Upgrade from '../models/Upgrade';
import { FormControlLabel, Checkbox, Typography } from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import FormHelperText from '@material-ui/core/FormHelperText';
import clsx from 'clsx';
import School from '../models/School';

type UpgradeCheckboxProps = {
  upgrade: Upgrade;
  key: string;
  school: School;
};

const { forceUpdate } = store.getHelpers('checked_upgrades');

const handleUpgradeCheck = (upgrade: Upgrade, event: React.ChangeEvent<HTMLInputElement>): void => {
  const upgrades = store.getState('checked_upgrades');

  if (event.target.checked) {
    upgrades.push(upgrade);
  } else {
    spliceFromArrayIfPresent(upgrades, upgrade);
  }

  forceUpdate();
};

const renderDesc = (desc: string, label: string): JSX.Element => {
  return (
    <FormHelperText className="ml-2 mb-3">
      {label ? ( <b>{label}: </b>): null}
      
      {desc}
    </FormHelperText>
  );
};

const UpgradeCheckbox = (props: UpgradeCheckboxProps): JSX.Element | null => {
  const upgrade = props.upgrade;
  const checked = store.getState('checked_upgrades').includes(upgrade);
  const cents = upgrade.getCents(props.school);

  if (cents == null) {
    return null;
  }

  const name = `${upgrade.addon ? 'Add ' : ''}${upgrade.labelName()}`;
  const desc = upgrade.description;
  const ss = upgrade.getProduct().short_short
  const hasDesc = !!desc && (ss === 'MF' || ss === 'FFC');

  clsx({
    'my-2': !hasDesc,
    'mt-2': hasDesc,
  });

  return (
    <div>
      <FormControlLabel
        key={`upgrade_cb_${upgrade.id}`}
        className="mt-2 mb-2"
        label={<Typography style={{ fontSize: '12px' }}>{name}</Typography>}
        control={
          <Checkbox
            checked={upgrade.required ? true : checked}
            onChange={(e): void => handleUpgradeCheck(upgrade, e)}
            color="primary"
            icon={<CheckBoxOutlineBlankIcon style={{ fontSize: 20 }} />}
            checkedIcon={<CheckBoxIcon style={{ fontSize: 20 }} />}
          />
        }
      />
      {hasDesc ? renderDesc(desc, upgrade.label) : null}
    </div>
  );
};

export default UpgradeCheckbox;
