import React from 'react';
import { centsToDollarString } from '../../util/util';

type SubmitProps = {
  processing: boolean;
  error: string | null | undefined;
  disabled: boolean;
  amt_cents: number;
};

const StripeSubmitButton = ({ processing, error, disabled, amt_cents }: SubmitProps): JSX.Element => {
  console.log('Pay ', centsToDollarString(amt_cents));
  const btn = 'Lock In My Discounted Rental Rates Right Now!';
  const txt = processing ? 'Processing...' : btn;

  return (
    <button
      className={`SubmitButton ${error ? 'SubmitButton--error' : ''}`}
      type="submit"
      disabled={processing || disabled}
    >
      {txt}
    </button>
  );
};

export default StripeSubmitButton;
