import { ClassName } from '../types/types';
import Product from './Product';
import { ModelJSON } from '../types/json_types';
import Upgrade from './Upgrade';

abstract class ApplicationModel {
  id: number;
  created_at: Date;

  constructor(attrs: ModelJSON) {
    this.id = attrs.id;
    this.created_at = new Date(attrs.created_at + 'Z');
  }

  isProduct(): this is Product {
    return this.getClassName() === 'Product';
  }

  isUpgrade(): this is Upgrade {
    return this.getClassName() === 'Upgrade';
  }

  abstract getClassName(): ClassName;
}

export default ApplicationModel;
