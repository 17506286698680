import React from 'react';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import IconButton from '@material-ui/core/IconButton';
import { store } from '../../constants/globals';
import { colors } from '../../constants/colors';

import { grey } from '@material-ui/core/colors';
import clsx from 'clsx';

const openCart = (): void => {
  store.setState('cart_drawer_open', true);
};

const AppBarRight = (): JSX.Element => {
  const cnt = store.useState('cart_count');
  const view = store.useState('viewShown');

  const disabled = view === 'Final' || view === 'Invoice' || view === 'Success';

  const color = disabled ? grey[500] : cnt > 0 ? colors.otro_success : 'white';

  const classNames = clsx({
    invisible: view === 'Invoice',
  });

  return (
    <IconButton
      className={classNames}
      disabled={disabled}
      edge="start"
      color="inherit"
      aria-label="menu"
      style={{ position: 'relative' }}
      onClick={openCart}
    >
      <ShoppingCartIcon style={{ color: color }} />
      {cnt > 0 && <div style={{ color: color, position: 'absolute', top: 2, right: 2, fontSize: 12 }}>{cnt}</div>}
    </IconButton>
  );
};

export default React.memo(AppBarRight);
