import React, { CSSProperties } from 'react';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

export type AppDatePickerProps = {
  onChangeDate: (date: Date | null) => void;
  date: Date | null;
  label: string;
  disabled?: boolean;
  helperText?: string;
  style?: CSSProperties;
};

const AppDatePicker = (props: AppDatePickerProps): JSX.Element => {
  const onChange = (d: MaterialUiPickersDate): void => {
    props.onChangeDate(d as Date);
  };
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DatePicker
        style={props.style}
        disabled={props.disabled || false}
        label={props.label}
        value={props.date}
        onChange={onChange}
        helperText={props.helperText ? props.helperText : null}
      />
    </MuiPickersUtilsProvider>
  );
};

export default AppDatePicker;
