/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { getModels } from '../../constants/globals';
import { FormControl, InputLabel, Select } from '@material-ui/core';
import sortBy from 'lodash/sortBy';
import { filterModelsByAttr } from '../../util/app_util';

type BuildingSelectProps = {
  building_id: string;
  school_id: number;
  label?: string;
  onChange: {
    (e: React.ChangeEvent<any>): void;
    <T = string | React.ChangeEvent<any>>(field: T): T extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };
};

const BuildingSelect = (props: BuildingSelectProps): JSX.Element => {
  let buildings = filterModelsByAttr(getModels('buildings'), 'school_id', props.school_id);
  buildings = sortBy(buildings, (b) => b.shown_name);

  return (
    <div className="d-flex justify-content-center align-items-center" style={{ marginTop: 5, width: '100%' }}>
      <FormControl variant="outlined" style={{ width: '100%' }}>
        <InputLabel htmlFor="school_select">Your Building</InputLabel>
        <Select
          native
          name="building_id"
          value={props.building_id || 'null'}
          onChange={props.onChange}
          label="Your Building"
          inputProps={{
            name: 'building_id',
            id: 'building_id',
          }}
        >
          <option value="null">{props.label || "I don't know yet"}</option>
          {buildings.map((b) => (
            <option key={`building_select_${b.id}`} value={b.id}>
              {b.shown_name}
            </option>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default BuildingSelect;
