import ApiRequest from '../../libs/ApiRequest';
import { store, line_items_key } from '../../constants/globals';
import { PaymentIntentPayload } from './StripeCheckoutForm';
import { isErrorResponse, InvoiceApiResp } from '../../types/json_types';
import { processInvoiceJSON } from '../../helpers/invoice_helpers';

const { setPartialState: setStripeForm } = store.getHelpers('stripe_form');

const handleStripeSubmit = async (
  createPaymentIntent: () => Promise<PaymentIntentPayload>,
  cardComplete: boolean,
): Promise<void> => {
  if (cardComplete) {
    setStripeForm({ processing: true });
  }

  const payload = await createPaymentIntent();

  // const funding = payload.paymentMethod?.card?.funding || 'unknown';

  if (payload.error) {
    setStripeForm({
      error: payload.error.message,
      processing: false,
    });
  } else {
    const invoice = store.getNonNullState('invoice');

    const req = new ApiRequest(
      '/store_api/pay_invoice.json',
      'POST',
      {
        onSuccess: (resp: unknown): void => {
          const json = resp as InvoiceApiResp;

          const invoice = processInvoiceJSON(json.base.invoice, json.base.reservation);

          window.App.calcResp = json.calc;

          localStorage.removeItem(line_items_key);
          store.setState('invoice', invoice);
        },
        onError: (json: unknown): void => {
          if (isErrorResponse(json)) {
            alert(`The following errors occurred: ${json.errors.join(' & ')}`);
          } else {
            alert('an error occurred while trying to make the purchase');
          }
        },
        onDone: (): void => {
          setStripeForm({ processing: false });
        },
      },
      {
        payment_method_token: payload?.paymentMethod?.id,
        invoice_id: invoice.id,
      },
    );

    req.perform();
  }
};

export default handleStripeSubmit;
