import React from 'react';
import { getModels, cart, store } from '../constants/globals';
import Product from '../models/Product';
import Button from '@material-ui/core/Button';
import { sortProductsByShorts, spliceItemsFromArray } from '../util/util';
import Box from '@material-ui/core/Box';
import Upgrade from '../models/Upgrade';
import { Typography, FormHelperText, Link } from '@material-ui/core';
import UpgradeCheckbox from './UpgradeCheckbox';
import UpgradeRadioGroup from './UpgradeRadioGroup';
import TvProduct from './TvProduct';
import { short_order } from '../constants/shorts';

const { useNonNullState: useSchool, getNonNullState: getSchool } = store.getHelpers('school');

const addProd = (product: Product): void => {
  const school = getSchool();
  const upgrades = store.getState('checked_upgrades').filter((u) => u.product_id === product.id);
  if (product.short === 'PM') {
    const has_size = !!store.getState('checked_upgrades').find((u) => u.product_id === product.id);

    if (has_size) {
      cart.addProduct(product, upgrades);
    } else {
      alert('Please select a mattress size');
    }
  } else {
    const required = product.getUpgrades(school).find((u) => u.required);
    if (required && !upgrades.includes(required)) {
      upgrades.push(required);
    }
    cart.addProduct(product, upgrades);
  }
};

const renderUpgrade = (upgrade: Upgrade): JSX.Element => {
  return <UpgradeCheckbox key={`upgrade_cb_${upgrade.id}`} upgrade={upgrade} school={getSchool()}></UpgradeCheckbox>;
};

const renderRadioGroup = (upgrades: Upgrade[]): JSX.Element | null => {
  if (!upgrades.length) {
    return null;
  }

  const checked = store.getState('checked_upgrades');
  const selected = checked.find((u) => upgrades.includes(u));

  const onChange = (upgrade: Upgrade): void => {
    spliceItemsFromArray(checked, upgrades);
    checked.push(upgrade);
    store.forceUpdate('checked_upgrades');
  };

  return (
    <div className="mt-3 radio-group">
      <UpgradeRadioGroup onChange={onChange} upgrades={upgrades} upgrade={selected || null}></UpgradeRadioGroup>{' '}
    </div>
  );
};

const renderProduct = (product: Product): JSX.Element | null => {
  if (product.short === 'TV32') {
    return <TvProduct key={'TV32'} />;
  } else if (product.short === 'TV40') {
    return null;
  } else {
    const k = `prod_${product.id}`;
    const img = product.getImages()[0];

    const school = getSchool();

    const upgrades = product.getUpgrades(school);

    return (
      <div key={k} className="d-flex mt-3 pb-4">
        <div style={{ minWidth: 100 }}>
          <img src={img} alt={product.name} />
        </div>
        <div className="d-flex flex-column ml-2 justify-content-between align-items-baseline">
          <Box display="flex">
            <Typography>{product.name}</Typography>
          </Box>
          {renderRadioGroup(product.getRadioGroup(school))}
          <div>{upgrades.map(renderUpgrade)}</div>
          {product.showDescription(school) && (
            <FormHelperText style={{ maxWidth: 600 }}>{product.description}</FormHelperText>
          )}
          {product.short === 'HB' && (
            <FormHelperText>
              Headboard purchases are fulfilled separately by our affiliate Hangin&apos; Headz LLC. After finishing your
              reservation here,{' '}
              <Link href="https://www.hanginheadz.com/" target="_blank">
                please visit their website
              </Link>{' '}
              to select your headboard style
            </FormHelperText>
          )}
          <Button variant="outlined" color="primary" onClick={(): void => addProd(product)}>
            Add to Cart
          </Button>
        </div>
      </div>
    );
  }
};

const Home = (): JSX.Element => {
  store.useState('checked_upgrades');
  const school = useSchool();

  let products = getModels('products').filter((p) => p.hasSchool(school));
  products = sortProductsByShorts(products, short_order);

  return (
    <div className="pl-1">
      {products.map(renderProduct)}
      <div className="mb-4">
        <Link href="http://dormsdirect.com/upgrades.html" target="_blank">
          *Subject to availability. Terms and Conditions Apply.
        </Link>
      </div>
    </div>
  );
};

export default Home;
