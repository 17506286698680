import React from 'react';
import Home from './Home';
import { store, router, getAllData, cart } from '../constants/globals';
import { View } from '../types/app_types';
import BadRoute from './BadRoute';
import ApiRequest, { ApiRequestCallbacks } from '../libs/ApiRequest';
import Container from '@material-ui/core/Container';
import { handleRoute } from '../init_app/router';
import { AllJSONResponse } from '../types/json_types';
import { handleData } from '../util/handle_data';
import AppBar from './app_bar/AppBar';
import CartDrawer from './CartDrawer';
import PickSchool from './PickSchool';
import Cart from '../models/Cart';
import StudentDetails from './checkout/StudentDetails';
import Drawer from './Drawer';
import ParentDetails from './checkout/ParentDetails';
import Final from './checkout/Final';
import InvoicePage from './InvoicePage';
import Success from './checkout/Success';
import UpdateRoom from './UpdateRoom';
import { UpdateDeparture } from './UpdateDeparture';

const { useStoreState: useView } = store.getHelpers('viewShown');

const getView = (view: View): JSX.Element => {
  switch (view) {
    case 'Home':
      return <Home />;
    case 'SelectSchool':
      return <PickSchool />;
    case 'StudentDetails':
      return <StudentDetails />;
    case 'ParentDetails':
      return <ParentDetails />;
    case 'Final':
      return <Final />;
    case 'Success':
      return <Success />;
    case 'BadRoute':
      return <BadRoute />;
    case 'Invoice':
      return <InvoicePage />;
    case 'UpdateRoom':
      return <UpdateRoom />;
    case 'UpdateDeparture':
      return <UpdateDeparture />;
    default:
      return <div>dunno</div>;
  }
};

const dataCallbacks: ApiRequestCallbacks = {
  onSuccess: (json: unknown) => {
    router.start();
    handleData(json as AllJSONResponse);
    window.D = getAllData();
    Cart.setCart();
    store.getStateObj().cart_count = cart.line_items.length;
    handleRoute(window.location.pathname);
  },
  onError: () => {
    alert('an error occurred while loading the data');
  },
};

const dataRequest = new ApiRequest(
  `/store_api/all_json.json?school_id=${localStorage.getItem('school_id')}`,
  'GET',
  dataCallbacks,
);

function fetchData(): void {
  if (dataRequest.status > 0) {
    return;
  }

  dataRequest.perform();
}

const Main = (): JSX.Element => {
  const viewShown = useView();

  let view: JSX.Element;

  if (viewShown === 'Invoice') {
    view = <InvoicePage />;
  } else {
    fetchData();
    if (dataRequest.isLoading()) {
      return <div></div>;
    }

    view = getView(viewShown || 'BadRoute');
  }

  return (
    <div>
      <AppBar />
      <Drawer />
      <CartDrawer />
      <div className="fit" style={{ top: 56 }}>
        <Container>{view}</Container>
      </div>
    </div>
  );
};

export default Main;
