import Product from './Product';
import Upgrade from './Upgrade';
import { arraysMatch } from '../util/util';
import School from './School';

export type LineItemJSON = {
  product_id: number;
  quantity: number;
  upgrade_ids: number[];
};

class LineItem {
  product: Product;
  quantity: number;
  upgrades: Upgrade[];

  constructor(product: Product, upgrades: Upgrade[], quantity = 1) {
    this.product = product;
    this.quantity = quantity;
    this.upgrades = upgrades;
  }

  upgradeIds(): number[] {
    return this.upgrades.map((u) => u.id).sort();
  }

  name(): string {
    if (this.upgrades.length) {
      return this.upgrades.map((u) => u.fullName()).join('; ');
    } else {
      return this.product.name;
    }
  }

  key(): string {
    if (this.upgrades.length) {
      return `li_prod_${this.product.id}_upgrade_${this.upgradeIds().join('_')}`;
    } else {
      return `li_prod_${this.product.id}`;
    }
  }

  getSpecials(): { addon?: Upgrade; radio?: Upgrade; isnew?: Upgrade } {
    const obj: { addon?: Upgrade; radio?: Upgrade; isnew?: Upgrade } = {};

    for (let index = 0; index < this.upgrades.length; index++) {
      const upgrade = this.upgrades[index];

      if (upgrade.isUpgrade()) {
        if (upgrade.radio) {
          obj.radio = upgrade;
        } else if (upgrade.isnew) {
          obj.isnew = upgrade;
        } else if (upgrade.addon) {
          obj.addon = upgrade;
        }
      }
    }

    return obj;
  }

  findRegularUpgrade(): Upgrade | null {
    for (let index = 0; index < this.upgrades.length; index++) {
      const upgrade = this.upgrades[index];

      if (upgrade.isUpgrade() && !upgrade.isSpecial()) {
        return upgrade;
      }
    }

    return null;
  }

  getLabel(): string {
    const regular_upgrade = this.findRegularUpgrade();

    let name = regular_upgrade?.name || this.product.name;

    const { addon, radio, isnew } = this.getSpecials();

    if (addon) {
      name = `${name} w/ ${addon.name}`;
    }

    if (radio) {
      name = `${radio.label.split(/[*(]/)[0]} ${name}`;
    }

    if (isnew) {
      if (isnew.required) {
        name = `NEW ${name}`;
      } else {
        name = `NEW (if available) ${name}`;
      }
    }

    if (this.quantity > 1) {
      name = `${name} x${this.quantity}`;
    }

    return name;
  }

  toJSON(): LineItemJSON {
    return {
      product_id: this.product.id,
      quantity: this.quantity,
      upgrade_ids: this.upgradeIds(),
    };
  }

  eq(line_item: LineItem): boolean {
    return this.product === line_item.product && arraysMatch(this.upgradeIds(), line_item.upgradeIds());
  }

  totalCents(school: School): number {
    let cents = this.product.getCents(school) || 0;

    this.upgrades.forEach((u) => (cents += u.getCents(school) || 0));

    return cents * this.quantity;
  }
}

export default LineItem;
