import React from 'react';
import MuiDrawer from '@material-ui/core/Drawer';
import HomeIcon from '@material-ui/icons/Home';
import SchoolIcon from '@material-ui/icons/School';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import List from '@material-ui/core/List';
import { store, router } from '../constants/globals';
import { ListItem, ListItemIcon, ListItemText, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Cart from '../models/Cart';

const { setState: setDrawerOpen, useStoreState: useDrawerOpen } = store.getHelpers('drawer_open');

const useStyles = makeStyles({
  list: {
    width: 250,
  },
});

const closeDrawer = (): void => {
  setDrawerOpen(false);
};

const go = (route: string) => (): void => {
  router.go(route);
};

const changeSchool = (): void => {
  localStorage.removeItem('school_id');
  store.getStateObj().school = undefined;
  Cart.clearCart();
  router.go('/');
};

export default function Drawer(): JSX.Element {
  const classes = useStyles();

  const open = useDrawerOpen();

  return (
    <MuiDrawer open={open} onClose={closeDrawer}>
      <div className={classes.list} role="presentation" onClick={closeDrawer} onKeyDown={closeDrawer}>
        <List>
          <ListItem button key={'home'} onClick={go('/')}>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary={'Home'} />
          </ListItem>
          <ListItem button key={'change_school'} onClick={changeSchool}>
            <ListItemIcon>
              <SchoolIcon />
            </ListItemIcon>
            <ListItemText primary={'Change School'} />
          </ListItem>

          <Link
            target="_blank"
            href="https://dormsdirect.com/contact.html"
            className="d-flex"
            style={{ color: 'black', textDecoration: 'none' }}
          >
            <ListItem button key={'Support'} onClick={changeSchool}>
              <ListItemIcon>
                <ContactSupportIcon />
              </ListItemIcon>
              <ListItemText primary={'Support'} />
            </ListItem>
          </Link>
        </List>
      </div>
    </MuiDrawer>
  );
}
