import React from 'react';
import StripeField from './StripeField';
import { store } from '../../constants/globals';

const StripeNameField = (name_on_card: string): JSX.Element => {
  return (
    <StripeField
      label="Name on card"
      id="name"
      type="text"
      placeholder="Jane Doe"
      required
      autoComplete="name"
      value={name_on_card}
      onChange={(e): void => {
        store.setPartialState('stripe_form', { name_on_card: e.target.value });
      }}
    />
  );
};

export default StripeNameField;
