import React from 'react';
import { store } from '../constants/globals';
import { isErrorResponse, InvoiceApiResp } from '../types/json_types';
import ApiRequest from '../libs/ApiRequest';
import { processInvoiceJSON, getStateForRoomUpdate } from '../helpers/invoice_helpers';
import BuildingSelect from './checkout/BuildingSelect';
import TextField from '@material-ui/core/TextField';
import { FormHelperText, Button } from '@material-ui/core';
import { resDetails, arrivingTxt } from '../helpers/reservation_helpers';
import { isPresent } from '../util/util';
import Alert from '@material-ui/lab/Alert';

const { setState, getNonNullState, useNonNullState, setPartialState } = store.getHelpers('update_room_page');

const clickUpdate = (): void => {
  const state = getNonNullState();

  const { building_id, room_wing, room_number } = state;

  const req = new ApiRequest(
    `/store_api/reservations/${state.invoice_token}.json`,
    'PATCH',
    {
      onSuccess: (resp: unknown): void => {
        const json = resp as InvoiceApiResp;
        const invoice = processInvoiceJSON(json.base.invoice, json.base.reservation);

        setPartialState({ invoice, success: true });
      },
      onError: (json: unknown): void => {
        if (isErrorResponse(json)) {
          alert(json.errors.join('&'));
        } else {
          alert(`couldnt find invoice with token ${state.invoice_token}`);
        }
      },
    },
    { reservation: { building_id, room_number, room_wing } },
  );
  req.perform();
};

const UpdateRoom = (): JSX.Element => {
  const state = useNonNullState();
  const { invoice, invoice_token, room_number, building_id, room_wing, working, success } = state;

  if (invoice) {
    const res = invoice.reservation;
    const school = res.getSchool();

    const { studentTxt, parentTxt, roomTxt } = resDetails(res);

    return (
      <div className="d-flex flex-column" style={{ maxWidth: 500, margin: 'auto' }}>
        <div className="p-1">
          <div>
            <b>Student: </b>
            {studentTxt}
          </div>
          {isPresent(roomTxt) && (
            <div>
              <b>Room: </b>
              {roomTxt}
            </div>
          )}
          <div>
            <b>Parent: </b>
            {parentTxt}
          </div>
          <div>
            <b>Expected student arrival date: </b>
            {arrivingTxt(res.arrival_date_at)}
          </div>

          {isPresent(res.special_instructions) ? (
            <div>
              <b>Special Instructions: </b>
              {res.special_instructions}
            </div>
          ) : null}
        </div>
        <div className="mt-3">
          <BuildingSelect
            label="Select your building"
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onChange={(e: React.ChangeEvent<any>): void =>
              setPartialState({ building_id: parseInt(e.target.value) || null })
            }
            school_id={school.id}
            building_id={building_id?.toString() || ''}
          />
        </div>
        <div className="mt-3">
          <TextField
            label="Room number"
            value={room_number}
            onChange={(e): void => setPartialState({ room_number: e.target.value })}
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
          />
        </div>
        <div className="mt-3">
          <TextField
            style={{ minWidth: 250 }}
            label="Wing/Suite letter (if applicable)"
            value={room_wing}
            onChange={(e): void => setPartialState({ room_wing: e.target.value })}
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
          />
          <FormHelperText>
            If you have a letter designation as part of your housing assignment (eg &apos;A&apos;), please enter it
            here.
          </FormHelperText>
        </div>

        {success && (
          <div className="mt-3">
            <Alert severity="success">
              Success! You&apos;ve set your room to {invoice.reservation.roomStr()}{' '}
              <div className="mt-1">Thanks for letting us know!</div>
            </Alert>
          </div>
        )}

        <div className="mt-3">
          <Button variant="outlined" color="primary" onClick={clickUpdate} disabled={working}>
            Update Room
          </Button>
        </div>
      </div>
    );
  } else {
    const req = new ApiRequest(`/store_api/get_invoice?token=${invoice_token}`, 'GET', {
      onSuccess: (resp: unknown): void => {
        const json = resp as InvoiceApiResp;
        const invoice = processInvoiceJSON(json.base.invoice, json.base.reservation);
        const state = getStateForRoomUpdate(invoice_token, invoice);
        setState(state);
      },
      onError: (json: unknown): void => {
        if (isErrorResponse(json)) {
          alert(json.errors.join('&'));
        } else {
          alert(`couldnt find invoice with token ${invoice_token}`);
        }
      },
    });
    req.perform();

    return <div></div>;
  }
};

export default UpdateRoom;
