import ApplicationModel from './ApplicationModel';
import { ClassName } from '../types/types';
import { ProductImageJSON } from '../types/json_types';
import { getImageUrl, ImageSize } from '../helpers/paperclip';

class ProductImage extends ApplicationModel {
  filename: string;
  product_id: number;

  constructor(json: ProductImageJSON) {
    super(json);

    this.product_id = json.product_id;
    this.filename = json.image_file_name;
  }

  getUrl(size: ImageSize): string {
    return getImageUrl(this.id, 'product_images', size, this.filename);
  }

  getClassName(): ClassName {
    const className: ClassName = 'Product';
    return className;
  }
}

export default ProductImage;
