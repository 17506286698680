import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import StripeCheckoutForm from './StripeCheckoutForm';
import { getEnvironment } from '../../helpers/paperclip';

export type StripeFormProps = {
  total: number;
};

const key =
  getEnvironment() === 'production'
    ? 'pk_live_7bxGAvoqsEaFhrNbFbYLCewD00A8HEx9aU'
    : 'pk_test_51GroNLID4juRKc4l3M8lcsRlYz3joRarOG3nKUhHvbfxJsjx9FNjSFN7Pg7kQwVaWXDYQ1kXGoDcwcdSSxqxGQsJ00I7K2cyj4';
const stripePromise = loadStripe(key);

const StripeFormStart = (props: StripeFormProps): JSX.Element => (
  <Elements stripe={stripePromise}>
    <StripeCheckoutForm {...props} />
  </Elements>
);

export default StripeFormStart;
