import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MuiAppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { IconButton, Typography, Container } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { store } from '../../constants/globals';
import AppBarRight from './AppBarRight';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  title: {
    textAlign: 'center',
    flexGrow: 1,
  },
}));

const handleResize = (): void => {
  const appbar = document.querySelector('.MuiAppBar-root');
  if (!appbar) {
    throw new Error('problem, no appbar');
  }

  const height = appbar.clientHeight;
  const fit = document.querySelector('.fit') as HTMLElement;

  fit.setAttribute('style', `top: ${height}px`);
};

const openDrawer = (): void => {
  store.setState('drawer_open', true);
};

export default function AppBar(): JSX.Element {
  const classes = useStyles();

  React.useLayoutEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
  }, []);

  const school = store.useState('school');

  return (
    <MuiAppBar position="static" elevation={0}>
      <Container>
        <Toolbar>
          <IconButton edge="start" color="inherit" aria-label="menu" onClick={openDrawer}>
            <MenuIcon />
          </IconButton>
          <div className={classes.title}>
            <Typography>Dorms Direct Reservations</Typography>

            {school ? <div style={{ textAlign: 'center', fontSize: 12 }}>{school.name}</div> : null}
          </div>
          <AppBarRight />
        </Toolbar>
      </Container>
    </MuiAppBar>
  );
}
