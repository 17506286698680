import React from 'react';
import { Grid, TextField, Typography, Button } from '@material-ui/core';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import find from 'lodash/find';
import { Formik } from 'formik';

import {
  cntInts,
  email_regexp,
  getDefaultParentData,
  formatPhoneNumber,
  getDefaultFinalState,
} from '../../helpers/form_helpers';
import { isDefined, isBlank, isPresent } from '../../util/util';
import { store } from '../../constants/globals';
import { ParentFormErrors } from '../../types/form_types';

let phoneNeedsFormatting = false;

const not_required_fields = ['parent_phone'];

const clickBack = (): void => {
  store.setState('viewShown', 'StudentDetails');
};

const ParentDetails = (): JSX.Element => {
  React.useEffect(() => {
    phoneNeedsFormatting = true;
  }, []);
  return (
    <Formik
      initialValues={getDefaultParentData()}
      validate={(values): ParentFormErrors => {
        const errors: ParentFormErrors = {};
        if (!values.parent_email) {
          errors.parent_email = 'Required';
        } else if (!email_regexp.test(values.parent_email)) {
          errors.parent_email = 'Invalid email address';
        }

        if (isPresent(values.parent_phone)) {
          const phone_int_cnt = cntInts(values.parent_phone);
          const phone_int_cnt_btwn_10_and_16 = phone_int_cnt >= 10 && phone_int_cnt <= 16;
          if (!phone_int_cnt_btwn_10_and_16) {
            errors.parent_phone = 'Invalid phone number';
          }
        }

        if (!values.parent_first_name) {
          errors.parent_first_name = 'Required';
        }

        if (!values.parent_last_name) {
          errors.parent_last_name = 'Required';
        }

        return errors;
      }}
      onSubmit={(values, { setSubmitting }): void => {
        window.App.parentDeets = values;
        store.getStateObj().final = getDefaultFinalState();
        store.setState('viewShown', 'Final');
      }}
    >
      {({ values, touched, errors, isSubmitting, handleChange, handleBlur, handleSubmit }): JSX.Element => {
        const has_errors = Object.keys(errors).length > 0;

        const has_any_empty_values = isDefined(
          find(values, (val, key): boolean => {
            return !not_required_fields.includes(key) && !val;
          }),
        );

        if (phoneNeedsFormatting && isBlank(errors.parent_phone) && isPresent(values.parent_phone)) {
          values.parent_phone = formatPhoneNumber(values.parent_phone) || values.parent_phone;
          phoneNeedsFormatting = false;
        }

        const btn_disabled = has_any_empty_values || has_errors || isSubmitting;

        const email_invalid = touched.parent_email && isPresent(errors.parent_email);
        const first_name_invalid = touched.parent_first_name && isPresent(errors.parent_first_name);
        const last_name_invalid = touched.parent_last_name && isPresent(errors.parent_last_name);
        const phone_invalid = touched.parent_phone && isPresent(errors.parent_phone);

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const blurPhone = (e: React.FocusEvent<any>): void => {
          handleBlur(e);

          phoneNeedsFormatting = true;
        };

        return (
          <div className="mt-2 p-2">
            <div className="d-flex justify-content-between mb-3 align-items-center">
              <Button color="primary" className="d-flex align-items-center" onClick={clickBack} variant="outlined">
                <ArrowBackIcon className="mr-1" />
                Back
              </Button>

              <Typography className="d-flex align-items-center" variant="h5" style={{ textAlign: 'center' }}>
                Parent Details
              </Typography>

              <Button
                disabled
                color="primary"
                className="d-flex align-items-center"
                variant="outlined"
                style={{ visibility: 'hidden' }}
              >
                <ArrowBackIcon className="mr-1" />
                Back
              </Button>
            </div>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <TextField
                  error={first_name_invalid}
                  helperText={first_name_invalid ? errors.parent_first_name : ''}
                  style={{ width: '100%' }}
                  className="mt-1"
                  name="parent_first_name"
                  label="First name*"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.parent_first_name}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  error={last_name_invalid}
                  helperText={last_name_invalid ? errors.parent_last_name : ''}
                  className="mt-1"
                  style={{ width: '100%' }}
                  name="parent_last_name"
                  value={values.parent_last_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  label="Last name*"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  error={email_invalid}
                  helperText={email_invalid ? errors.parent_email : ''}
                  className="mt-1"
                  style={{ width: '100%' }}
                  value={values.parent_email}
                  name="parent_email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  label="Parent email*"
                  type="parent_email"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  error={phone_invalid}
                  helperText={phone_invalid ? 'invalid phone number' : ''}
                  className="mt-1"
                  style={{ width: '100%' }}
                  value={values.parent_phone}
                  onChange={handleChange}
                  onBlur={blurPhone}
                  label="Parent phone number (optional)"
                  name="parent_phone"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12} className="my-3">
                <Button
                  style={{ width: '100%' }}
                  onClick={(): void => handleSubmit()}
                  size="large"
                  variant="contained"
                  color="primary"
                  disableElevation
                  disabled={btn_disabled}
                >
                  Next
                </Button>
              </Grid>
            </Grid>
          </div>
        );
      }}
    </Formik>
  );
};

export default ParentDetails;
