import React from 'react';
import { store } from '../init_app/store';
import MuiDrawer from '@material-ui/core/Drawer';
import Typography from '@material-ui/core/Typography';
import LineItem from '../models/LineItem';
import { cart, router } from '../constants/globals';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import AddCircleIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircleOutline';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

const { useStoreState: useCartDrawerOpen, setState } = store.getHelpers('cart_drawer_open');

const clickClose = (): void => {
  setState(false);
};

const renderItem = (li: LineItem): JSX.Element => {
  return (
    <div
      key={li.key()}
      className="d-flex align-items-center justify-content-between mt-1"
      style={{ borderBottom: '1px solid #e4e4e4' }}
    >
      <div className="d-flex flex-wrap align-items-center">
        <div>{li.getLabel()}</div>
      </div>

      <div className="ml-1 d-flex align-items-center">
        <IconButton
          onClick={(): void => {
            cart.addQuantity(li);
          }}
        >
          <AddCircleIcon fontSize="inherit" />
        </IconButton>
        {li.quantity}
        <IconButton onClick={(): void => cart.subtractQuantity(li)}>
          <RemoveCircleIcon fontSize="inherit" />
        </IconButton>
      </div>
    </div>
  );
};

const clickCheckout = (): void => {
  router.go('/checkout');
  setTimeout(() => {
    store.setState('cart_drawer_open', false);
  }, 1);
};

const CartDrawer = (): JSX.Element | null => {
  const open = useCartDrawerOpen();

  const line_items = cart.line_items;

  const school = store.getState('school');

  if (!school) {
    return null;
  }

  return (
    <MuiDrawer open={open} onClose={clickClose} anchor="right">
      <div id="drawer" style={{ width: 300 }} className="px-2">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography onClick={clickClose} variant="h5">
            Your Cart
          </Typography>
          <IconButton onClick={clickClose}>
            <ClearIcon fontSize="large" />
          </IconButton>
        </Box>
        <div>{line_items.map(renderItem)}</div>
        <div className="mt-4 d-flex">
          <Button variant="outlined" color="primary" style={{ width: '100%' }} onClick={clickCheckout}>
            Checkout
          </Button>
        </div>
      </div>
    </MuiDrawer>
  );
};

export default CartDrawer;
