import LineItem, { LineItemJSON } from './LineItem';
import Product from './Product';
import { store, getModels, cart, line_items_key } from '../constants/globals';
import sumBy from 'lodash/sumBy';
import Upgrade from './Upgrade';
import reject from 'lodash/reject';
import { findModelById } from '../util/app_util';
import School from './School';

class Cart {
  line_items: LineItem[];

  constructor(line_items: LineItem[]) {
    this.line_items = line_items;
  }

  addQuantity(line_item: LineItem): void {
    line_item.quantity += 1;
    store.forceUpdate('cart_drawer_open');
    this.countChanged();
  }

  subtractQuantity(line_item: LineItem): void {
    line_item.quantity -= 1;

    if (line_item.quantity <= 0) {
      this.line_items = reject(this.line_items, (li): boolean => {
        return li === line_item;
      });
    }

    store.forceUpdate('cart_drawer_open');
    this.countChanged();
  }

  subtotalCents(school: School): number {
    return sumBy(this.line_items, (li) => {
      return li.totalCents(school);
    });
  }

  static clearCart(): void {
    cart.line_items = [];
    cart.countChanged();
  }

  toJSON(): LineItemJSON[] {
    return this.line_items.map((li) => li.toJSON());
  }

  saveCart(): void {
    const json_str = JSON.stringify(this.toJSON());
    localStorage.setItem(line_items_key, json_str);
  }

  static setCart(): void {
    const json_str = localStorage.getItem(line_items_key);
    const json: LineItemJSON[] = json_str ? JSON.parse(json_str) : [];
    const line_items = json.map((j) => {
      const product = findModelById(getModels('products'), j.product_id);
      const upgrades = j.upgrade_ids.map((uid) => findModelById(getModels('upgrades'), uid));

      return new LineItem(product, upgrades, j.quantity);
    });

    cart.line_items = line_items;
  }

  countChanged(): void {
    const cnt = sumBy(this.line_items, (li: LineItem): number => {
      return li.quantity;
    });

    store.setState('cart_count', cnt);
    this.saveCart();
    if (window.student_form) {
      window.student_form.validateForm();
    }
  }

  addProduct(product: Product, upgrades: Upgrade[]): void {
    const temp_li = new LineItem(product, upgrades);

    const li = this.line_items.find((li) => li.eq(temp_li));

    if (li) {
      li.quantity += 1;
    } else {
      const li = new LineItem(product, upgrades);
      this.line_items.push(li);
    }

    store.setState('cart_drawer_open', true);
    this.countChanged();
  }
}

export default Cart;
