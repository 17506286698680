export const shorts = ['BL', 'MF', 'FFC', 'SR', 'BS', 'LBX', 'LBXP', 'TV32', 'TV40', 'DFT', 'FT', 'PM', 'HB'];
export const short_shorts = ['BL', 'MF', 'FFC', 'SR', 'BS', 'LBX', 'TV', 'FT', 'PM', 'HB'];
export const short_order = ['PM', 'BL', 'SR', 'BS', 'MF', 'FFC', 'FT', 'DFT', 'LBX', 'LBXP',  'TV32', 'TV40', 'HB'];

type StringMap = {
  [key: string]: string;
};

const shortShortMap: StringMap = {
  BL: 'BL',
  MF: 'MF',
  FFC: 'FFC',
  SR: 'SR',
  BS: 'BS',
  LBX: 'LBX',
  LBXP: 'LBX',
  TV32: 'TV',
  TV40: 'TV',
  FT: 'FT',
  DFT: 'FT',
  HB: 'HB',
  PM: 'PM',
};

export const getShortShort = (short: string): string => {
  const short_short = shortShortMap[short];

  if (!short_short) {
    throw new Error(`${short} couldnt be mapped to short_short`);
  }

  return short_short;
};
