import { StripeForm } from '../types/state_types';

export const getDefaultStripeFormState = (): StripeForm => {
  return {
    error: '',
    cardComplete: false,
    processing: false,
    name_on_card: '',
  };
};
