import React from 'react';
import { findModelByAttr } from '../util/app_util';
import { getModels, cart, store } from '../constants/globals';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import { FormControlLabel, FormHelperText, FormControl, FormLabel, RadioGroup, Radio, Button } from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Product from '../models/Product';
import Upgrade from '../models/Upgrade';
import compact from 'lodash/compact';

let num = 0;
const TvProduct = (): JSX.Element | null => {
  const [tv_package_checked, setChecked] = React.useState(false);
  const [size, setSize] = React.useState<'32' | '40' | null>(null);

  const school = store.getNonNullState('school');

  const tv32 = findModelByAttr(getModels('products'), 'short', 'TV32');
  const cents = tv32.getCents(school);

  if (cents === null) {
    return null;
  }

  const tv40 = findModelByAttr(getModels('products'), 'short', 'TV40');
  const tv32_upgrade = findModelByAttr(getModels('upgrades'), 'product_id', tv32.id);
  const tv40_upgrade = findModelByAttr(getModels('upgrades'), 'product_id', tv40.id);

  const k = `tvs_prod_${num++}`;
  const img = tv32.getImages()[0];

  const name = 'HD Flatscreen TV';
  const upgrade = getModels('upgrades').find((u) => u.product_id === tv32.id);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const v = e.target.value;
    if (v === 'null') {
      setSize(null);
    } else {
      setSize(v === '32' ? '32' : '40');
    }
  };

  const addTv = (): void => {
    if (!size) {
      alert('Please select a TV size');
      return;
    }

    let prod: Product;
    let up: Upgrade | null = null;

    if (size === '40') {
      prod = tv40;
      up = tv_package_checked ? tv40_upgrade : null;
    } else {
      prod = tv32;
      up = tv_package_checked ? tv32_upgrade : null;
    }

    cart.addProduct(prod, compact([up]));
  };

  const has_upgrade = tv32_upgrade.getCents(school) != null || tv40_upgrade.getCents(school) != null;

  return (
    <div key={k} className="d-flex mt-3 pb-4">
      <div style={{ minWidth: 100 }}>
        <img src={img} alt={name} />
      </div>

      <div className="d-flex flex-column ml-2 justify-content-between align-items-baseline">
        <Box display="flex">
          <Typography>{name}</Typography>
        </Box>

        <FormControl component="fieldset" className="radio-group mt-3">
          <FormLabel component="legend">TV Size</FormLabel>
          <RadioGroup value={size || 'null'} onChange={handleChange}>
            <FormControlLabel key={`tv_32`} value={'32'} control={<Radio />} label="32 Inch" />
            {tv40.getCents(school) != null && (
              <FormControlLabel key={`tv_40`} value={'40'} control={<Radio />} label="40 Inch" />
            )}
          </RadioGroup>
        </FormControl>

        {has_upgrade && (
          <div>
            <FormControlLabel
              className="mt-2 mb-2"
              label={<Typography style={{ fontSize: '12px' }}>Add TV Connection Package*</Typography>}
              control={
                <Checkbox
                  checked={tv_package_checked}
                  onChange={(): void => setChecked(!tv_package_checked)}
                  color="primary"
                  icon={<CheckBoxOutlineBlankIcon style={{ fontSize: 20 }} />}
                  checkedIcon={<CheckBoxIcon style={{ fontSize: 20 }} />}
                />
              }
            />
            <FormHelperText className="ml-2 mb-3">
              <b>tv connection package*: </b>
              {upgrade?.description}
            </FormHelperText>
          </div>
        )}

        <Button variant="outlined" color="primary" onClick={addTv}>
          Add to Cart
        </Button>
      </div>
    </div>
  );
};

export default TvProduct;
