import { Store } from 'set-state-is-great';
import StateRouter from '../libs/StateRouter';
import { AppState } from '../types/state_types';
import { RouteState } from '../init_app/router';
import { AllModels } from '../types/types';
import Cart from '../models/Cart';

export const data: Partial<AllModels> = {};
export let store: Store<AppState>;
export let router: StateRouter<RouteState>;
export let cart: Cart;
export const line_items_key = 'line_items1';

export const getStore = (): Store<AppState> => {
  return store;
};

export function getAllData(): Partial<AllModels> {
  return data;
}

export const setGlobals = (theStore: Store<AppState>, theRouter: StateRouter<RouteState>, theCart: Cart): void => {
  router = theRouter;
  store = theStore;
  cart = theCart;

  window.student_form = null;
  window.App = {
    router,
    store,
    cart,
  };
};

export function setModels<Key extends keyof AllModels>(key: Key, models: AllModels[Key]): AllModels[Key] {
  data[key] = models;

  return models;
}

export function getModels<Key extends keyof AllModels>(key: Key): NonNullable<Partial<AllModels>[Key]> {
  const models = data[key];

  if (models) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return models!;
  } else {
    throw new Error(`model ${key} missing`);
  }
}
