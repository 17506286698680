import { green, blue, lightBlue, red } from '@material-ui/core/colors';

export const colors = {
  primary: blue[700],
  secondary: lightBlue[500],
  success: green[500],
  danger: red[900],
  bs_success: '#28a745',
  otro_success: green['A400'],
};
