import React from 'react';
import { Typography, FormControlLabel, Checkbox, FormHelperText } from '@material-ui/core';
import AppDatePicker from '../util/AppDatePicker';
import { store } from '../../constants/globals';
import { FinalState } from '../../types/state_types';

export type ArrivalDateSectionProps = {
  onChangeDate: (date: Date | null) => void;
  onChangeBool: (bool: boolean | null) => void;
  date: Date | null;
  arrivingPrior: boolean | null;
  arrivalUnknown: boolean;
};

const handleCheck = (event: React.ChangeEvent<HTMLInputElement>): void => {
  const checked = event.target.checked;
  const state: Partial<FinalState> = { arrival_unknown: checked };
  if (checked) {
    state.arrival_date_at = null;
  }
  store.setPartialState('final', state);
};

const ArrivalDateSection = (props: ArrivalDateSectionProps): JSX.Element => {
  return (
    <div style={{ marginTop: 10, width: '100%' }} className="p-1">
      <Typography>
        What day do you anticipate you will arrive on campus? (if you are uncertain, put down the earliest possible day
        you think that might be)
      </Typography>
      <FormHelperText>
        *While we will ALWAYS do our best to have everything delivered and installed prior to your arrival on campus,
        this year has many variables that are out of our control. However, we are still 90% certain we will have your
        items waiting for you in your room when you arrive. So long as you place your reservation prior to the cutoff
        deadline, we will make sure you receive your items prior to the start of classes. We appreciate your patience
        and understanding in this matter.
      </FormHelperText>

      <div className="mt-2">
        <AppDatePicker
          label="Planned date of arrival"
          date={props.date}
          onChangeDate={props.onChangeDate}
          disabled={props.arrivalUnknown}
          helperText="This is supposed to be your move in date. We will attempt to deliver everything by this date, but it is not guaranteed."
        ></AppDatePicker>
        <FormControlLabel
          className="mt-2"
          control={
            <Checkbox checked={props.arrivalUnknown} onChange={handleCheck} name="arrivalUnknown" color="primary" />
          }
          label="I'm not sure when I'll arrive"
        />
      </div>
    </div>
  );
};

export default ArrivalDateSection;
