import StateRouter from '../libs/StateRouter';
import { View } from '../types/app_types';
import { getStore, store } from '../constants/globals';
import { getDefaultStripeFormState } from '../helpers/stripe_helpers';
import { getStateForDepartureUpdate, getStateForRoomUpdate } from '../helpers/invoice_helpers';

export type RouteState = {
  title: string | null;
  viewShown: View;
};

const homeRoute = (): RouteState => {
  const school = store.getState('school');

  if (school) {
    return { title: 'Home', viewShown: 'Home' };
  } else {
    return { title: 'Select a school', viewShown: 'SelectSchool' };
  }
};

const successRoute = (): RouteState => {
  return { title: 'Success', viewShown: 'Success' };
};

const checkoutRoute = (): RouteState => {
  const school = store.getState('school');
  if (!school) {
    throw new Error('school_id should be set here');
  }

  return { title: 'Checkout', viewShown: 'StudentDetails' };
};

const invoiceRoute = (token: string): RouteState => {
  window.App.invoice_token = token;
  const obj = store.getStateObj();

  obj.invoice = undefined;
  obj.pay_w_cash = obj.pay_w_cash_working = false;
  obj.stripe_form = getDefaultStripeFormState();
  return { title: 'Your Invoice', viewShown: 'Invoice' };
};

const updateRoomRoute = (token: string): RouteState => {
  window.App.invoice_token = undefined;
  const obj = store.getStateObj();
  obj.update_room_page = getStateForRoomUpdate(token, null);
  return { title: 'Update Room', viewShown: 'UpdateRoom' };
};

const updateDepartureRoute = (token: string): RouteState => {
  window.App.invoice_token = undefined;
  const obj = store.getStateObj();
  obj.update_departure_page = getStateForDepartureUpdate(token, null);
  return { title: 'Update Departure', viewShown: 'UpdateDeparture' };
};

const routes = {
  '/': homeRoute,
  '/checkout': checkoutRoute,
  '/success': successRoute,
  '/invoices/:token': invoiceRoute,
  '/invoices/:token/update_room': updateRoomRoute,
  '/invoices/:token/update_departure_date': updateDepartureRoute,
};

export const routeHandler = (state: RouteState): void => {
  const store = getStore();

  store.setState('viewShown', state.viewShown);

  setTimeout(() => {
    store.setState('pageTitle', state.title || '');
  }, 1);
};

const handleBadRoute = (): RouteState => {
  return { title: 'Bad Route', viewShown: 'BadRoute' };
};

const router = new StateRouter<RouteState>(routes, routeHandler, handleBadRoute);

export const handleRoute = (path: string): void => {
  const state = router.stateFromPath(path);

  if (state) {
    routeHandler(state);
  } else {
    store.setState('viewShown', 'BadRoute');
  }
};

export default router;
