import { StripeFormProps } from './StripeFormStart';
import { store } from '../../constants/globals';
import { StripeError, PaymentMethod } from '@stripe/stripe-js';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import React from 'react';
import { Typography } from '@material-ui/core';
import handleStripeSubmit from './handleStripeSubmit';
import StripeNameField from './StripeNameField';
import StripeCardForm from './StripeCardForm';
import StripeErrorMessage from './StripeErrorMessage';
import StripeSubmitButton from './StripeSubmitButton';
import { getEnvironment } from '../../helpers/paperclip';

const { useNonNullState: useStripeForm } = store.getHelpers('stripe_form');

// export type StripePayload = {
//   token?: Token | undefined;
//   error?: StripeError | undefined;
// };

export type PaymentIntentPayload = {
  paymentMethod?: PaymentMethod | undefined;
  error?: StripeError | undefined;
};

const StripeCheckoutForm = (props: StripeFormProps): JSX.Element | null => {
  const stripe = useStripe();
  const elements = useElements();

  const { error, cardComplete, processing, name_on_card } = useStripeForm();

  if (!stripe) {
    return null;
  }

  if (!elements) {
    return null;
  }

  const focusForm = (): void => {
    elements.getElement('card')?.focus();
  };

  // const createStripeToken = async (): Promise<StripePayload> => {
  //   const ele = elements.getElement(CardElement);
  //   if (!ele) {
  //     throw new Error('no stripe ele');
  //   }
  //   return await stripe.createToken(ele, { name: name_on_card });
  // };

  const createPaymentIntent = async (): Promise<PaymentIntentPayload> => {
    const ele = elements.getElement(CardElement);
    if (!ele) {
      throw new Error('no stripe ele');
    }

    const payload = await stripe.createPaymentMethod({
      type: 'card',
      card: ele,
      billing_details: { name: name_on_card },
    });

    return payload;
  };

  return (
    <div className="d-flex justify-content-center">
      <div
        className="stripe-wrapper flex-grow-1 pb-3 mt-3 pt-1"
        style={{ backgroundColor: '#6772e5', borderRadius: 5, color: 'white', position: 'relative' }}
      >
        <Typography className="text-align-center">Your payment details</Typography>
        <div className="text-align-center">Credit or debit card:</div>
        <form
          onSubmit={(e): void => {
            e.preventDefault();

            if (error) {
              focusForm();
            } else {
              handleStripeSubmit(createPaymentIntent, cardComplete);
            }
          }}
          className="mt-1"
        >
          <fieldset className="FormGroup">{StripeNameField(name_on_card)}</fieldset>
          <fieldset className="FormGroup">
            <StripeCardForm />
          </fieldset>

          {error && <StripeErrorMessage message={error}></StripeErrorMessage>}

          <StripeSubmitButton
            processing={processing}
            error={error}
            disabled={!stripe || !!error}
            amt_cents={props.total}
          />
        </form>
        {getEnvironment() !== 'production' && (
          <div>
            <div className="p-1 mt-2">
              Test card: 4242 4242 4242 4242, a valid expiration date in the future, and any CVC number and zip code.
            </div>

            <div className="p-1 mt-2">Test DEBIT card: 4000 0566 5566 5556</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default StripeCheckoutForm;
