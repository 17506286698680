import React from 'react';
import { CardElement } from '@stripe/react-stripe-js';
import { StripeCardElementChangeEvent, StripeCardElementOptions } from '@stripe/stripe-js';
import { store } from '../../constants/globals';

const CARD_OPTIONS: StripeCardElementOptions = {
  iconStyle: 'solid',
  style: {
    base: {
      iconColor: '#c4f0ff',
      color: '#fff',
      fontWeight: '500',
      fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': { color: '#fce883' },
      '::placeholder': { color: '#87bbfd' },
    },
    invalid: {
      iconColor: '#ffc7ee',
      color: '#ffc7ee',
    },
  },
};

const StripeCardForm = (): JSX.Element => {
  return (
    <div className="FormRow">
      <CardElement
        options={CARD_OPTIONS}
        onChange={(e: StripeCardElementChangeEvent): void => {
          store.setPartialState('stripe_form', {
            error: e.error?.message,
            cardComplete: e.complete,
          });
        }}
      />
    </div>
  );
};

export default StripeCardForm;
