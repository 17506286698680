import { UpgradeJSON } from '../types/json_types';
import ApplicationModel from './ApplicationModel';
import { ClassName } from '../types/types';
import Product from './Product';
import { getModels } from '../constants/globals';
import { findModelById } from '../util/app_util';
import School from './School';
import { isPresent } from '../util/util';

class Upgrade extends ApplicationModel {
  product_id: number;
  name: string;
  label: string;
  sku: number;
  methodist_price_cents: number | null;
  wm_price_cents: number | null;
  richmond_price_cents: number | null;
  bland_price_cents: number | null;
  addon: boolean;
  radio: boolean;
  isnew: boolean;
  product?: Product;
  description: string;
  short: string;
  required: boolean;

  constructor(json: UpgradeJSON) {
    super(json);

    this.product_id = json.product_id;
    this.name = json.name;
    this.label = json.label;
    this.description = json.description;
    this.sku = json.sku;
    this.methodist_price_cents = json.methodist_price_cents;
    this.wm_price_cents = json.wm_price_cents;
    this.richmond_price_cents = json.richmond_price_cents;
    this.bland_price_cents = json.bland_price_cents;
    this.addon = json.addon;
    this.radio = json.radio;
    this.isnew = json.isnew;
    this.short = json.short;
    // this.required = json.short === 'new_ffc';
    this.required = false;
  }

  getCents(school: School): number | null {
    if (school.isMethodist()) {
      return this.methodist_price_cents;
    } else if (school.isRichmond()) {
      return this.richmond_price_cents;
    } else if (school.isBland()) {
      return this.bland_price_cents;
    } else {
      return this.wm_price_cents;
    }
  }

  isSpecial(): boolean {
    return this.radio || this.addon || this.isnew;
  }

  fullName(): string {
    if (this.addon) {
      return `${this.getProduct().name} w/ ${this.name}`;
    } else {
      return this.name;
    }
  }

  labelName(): string {
    return this.label && isPresent(this.label) ? this.label : this.name;
  }

  getProduct(): Product {
    if (this.product) {
      return this.product;
    } else {
      this.product = findModelById(getModels('products'), this.product_id);
      return this.product;
    }
  }

  getClassName(): ClassName {
    return 'Upgrade';
  }
}

export default Upgrade;
