import React from 'react';
import '../init_app/app_starts_here';
import Main from './Main';
import '../App.css';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import clsx from 'clsx';
import { store, router } from '../constants/globals';
import { colors } from '../constants/colors';

const { useStoreState } = store.getHelpers('colormode');

const Top = (): JSX.Element => {
  const colormode = useStoreState();

  const theme = createMuiTheme({
    palette: {
      type: colormode,
      primary: {
        main: colors.primary,
      },
      secondary: {
        main: colors.secondary,
      },
    },
  });

  router.start();

  const classes = clsx({
    darkmode: colormode === 'dark',
    lightmode: colormode === 'light',
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div id="top" className={classes} style={{ backgroundColor: 'white' }}>
        <Main />
      </div>
    </ThemeProvider>
  );
};

export default Top;
