import ApplicationModel from './ApplicationModel';
import { ReservationJSON, TableName, SchoolYear, ReservationStatus } from '../types/json_types';
import { ClassName } from '../types/types';
import { getModels } from '../constants/globals';
import { findModelById } from '../util/app_util';
import School from './School';
import Building from './Building';

class Reservation extends ApplicationModel {
  student_first_name: string;
  student_last_name: string;
  student_phone: string;
  student_email: string;
  year: SchoolYear;
  school_id: number;
  building_id: number | null;
  room_number: string | null;
  room_wing: string | null;
  special_instructions: string;
  arrival_date_at: Date | null;
  arriving_prior: boolean | null;
  arrival_unknown: boolean | null;
  parent_first_name: string;
  parent_last_name: string;
  parent_phone: string | null;
  parent_email: string;
  status: ReservationStatus | null;
  archived: boolean | null;
  departure_date?: string;

  constructor(json: ReservationJSON) {
    super(json);

    this.student_first_name = json.student_first_name;
    this.student_last_name = json.student_last_name;
    this.student_phone = json.student_phone;
    this.student_email = json.student_email;
    this.year = json.year;
    this.school_id = json.school_id;
    this.building_id = json.building_id;
    this.room_number = json.room_number;
    this.room_wing = json.room_wing;
    this.special_instructions = json.special_instructions;
    this.arrival_date_at = json.arrival_date_at ? new Date(json.arrival_date_at + 'Z') : null;
    this.arriving_prior = json.arriving_prior;
    this.arrival_unknown = json.arrival_unknown;
    this.parent_first_name = json.parent_first_name;
    this.parent_last_name = json.parent_last_name;
    this.parent_phone = json.parent_phone;
    this.parent_email = json.parent_email;
    this.status = json.status;
    this.archived = json.archived;
    this.departure_date = json.departure_date;
  }

  getClassName(): ClassName {
    const className: ClassName = 'Reservation';
    return className;
  }

  static getTableName(): TableName {
    return 'reservations';
  }

  getSchool(): School {
    return findModelById(getModels('schools'), this.school_id);
  }

  getBuilding(): Building | null {
    if (this.building_id) {
      return findModelById(getModels('buildings'), this.building_id);
    } else {
      return null;
    }
  }

  studentFullName(): string {
    return `${this.student_first_name} ${this.student_last_name}`;
  }

  parentFullName(): string {
    return `${this.parent_first_name} ${this.parent_last_name}`;
  }

  roomStr(): string {
    let str = '';

    const building = this.getBuilding();
    if (building) {
      str = building.shown_name;

      if (this.room_number?.trim()) {
        str = `${str}, ${this.room_number}`;

        if (this.room_wing?.trim()) {
          str = `${str} ${this.room_wing}`;
        }
      }
    }

    return str;
  }
}

export default Reservation;
