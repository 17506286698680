import { AllJSONResponse } from '../types/json_types';
import { setModels, store } from '../constants/globals';
import Product from '../models/Product';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import ProductImage from '../models/ProductImage';
import { shorts } from '../constants/shorts';
import Upgrade from '../models/Upgrade';
import School from '../models/School';
import Building from '../models/Building';
import { findModelById } from './app_util';

export const handleData = function (allJson: AllJSONResponse): void {
  const schools = setModels(
    'schools',
    allJson.schools.map((s) => new School(s)),
  );

  const school_id_str = localStorage.getItem('school_id');
  if (school_id_str) {
    const school_id = parseInt(school_id_str);

    if (school_id) {
      store.getStateObj().school = findModelById(schools, school_id);
    }
  }

  setModels(
    'upgrades',
    allJson.upgrades.map((u) => new Upgrade(u)),
  );

  const productImages = setModels(
    'product_images',
    allJson.product_images.map((pi) => new ProductImage(pi)),
  );

  const groupedImages = groupBy(productImages, (pi) => pi.product_id);

  const products = sortBy(
    allJson.products.map((p) => new Product(p, groupedImages[p.id])),
    (prod: Product) => {
      shorts.indexOf(prod.short);
    },
  );
  setModels('products', products);

  setModels(
    'buildings',
    allJson.buildings.map((b) => new Building(b)),
  );
};
