const makeRequest = async function (
  path: string,
  method: HTTPMethod,
  payload?: PlainObject,
): Promise<{
  response: Response;
  json: unknown;
}> {
  const options: RequestInit = {
    method: method,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      // 'Cache-Control': 'no-cache',
    },
    credentials: 'same-origin',
  };

  if (payload) {
    options.body = JSON.stringify(payload);
  }

  const response = await fetch(path, options);

  let json: unknown;
  if (response.status === 204) {
    json = {};
  } else {
    try {
      json = await response.json();
    } catch {
      json = {};
    }
  }

  return {
    response: response,
    json: json,
  };
};

type PlainObject = { [name: string]: unknown };

type HTTPMethod = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';

export type ApiRequestCallbacks = {
  onLoading?: () => void;
  onSuccess: (json: unknown) => void;
  onDone?: () => void;
  onError: (json: unknown) => void;
};

enum Status {
  Idle,
  Loading,
  Success,
  Error,
}

class ApiRequest {
  path: string;
  method: HTTPMethod;
  http_status?: number;
  payload?: PlainObject;
  success?: boolean;
  error?: boolean;
  status: Status;
  callbacks: ApiRequestCallbacks;

  constructor(path: string, method: HTTPMethod, callbacks: ApiRequestCallbacks, payload?: PlainObject) {
    this.path = path;
    this.status = Status.Idle;
    this.method = method;
    this.callbacks = callbacks;
    this.payload = payload;
    this.http_status = undefined;
  }

  isLoading(): boolean {
    return this.status === Status.Loading;
  }

  async perform(): Promise<void> {
    this.status = Status.Loading;

    if (this.callbacks.onLoading) {
      this.callbacks.onLoading();
    }

    const result = await makeRequest(this.path, this.method, this.payload);

    const { json, response } = result;

    this.status = response.status;

    if (this.status >= 200 && this.status < 300) {
      this.status = Status.Success;

      this.callbacks.onSuccess(json);
    } else if (this.status >= 400 && this.status < 600) {
      this.status = Status.Error;

      this.callbacks.onError(json);
    }

    if (this.callbacks.onDone) {
      this.callbacks.onDone();
    }
  }
}

export default ApiRequest;
