import ApplicationModel from './ApplicationModel';
import { BuildingJSON, TableName } from '../types/json_types';
import { ClassName } from '../types/types';

class Building extends ApplicationModel {
  name: string;
  shown_name: string;
  school_id: number;

  constructor(json: BuildingJSON) {
    super(json);

    this.name = json.name;
    this.shown_name = json.shown_name;
    this.school_id = json.school_id;
  }

  getClassName(): ClassName {
    const className: ClassName = 'Building';
    return className;
  }

  static getTableName(): TableName {
    return 'buildings';
  }
}

export default Building;
