import ApplicationModel from './ApplicationModel';
import { InvoiceJSON, TableName } from '../types/json_types';
import { ClassName } from '../types/types';
import Reservation from './Reservation';

export type InvoiceStatus =
  | 'CREATED'
  | 'SENT'
  | 'CASH_PAYMENT_PENDING'
  | 'PAYMENT_PENDING'
  | 'PAID'
  | 'CANCELLED'
  | 'REFUNDED';

class Invoice extends ApplicationModel {
  reservation_id: number;
  reservation: Reservation;
  token: string;
  total_cents: number | null;
  final_charge_cents: number | null;
  status: InvoiceStatus;
  fee_cents: number | null;
  pay_w_cash: boolean;
  funding_type: 'CREDIT' | 'DEBIT' | null;

  constructor(json: InvoiceJSON, reservation: Reservation) {
    super(json);

    this.reservation_id = json.reservation_id;

    this.pay_w_cash = json.pay_w_cash;
    this.reservation = reservation;
    this.token = json.token;
    this.status = json.status;
    this.final_charge_cents = json.final_charge_cents;
    this.total_cents = json.total_cents;
    this.fee_cents = json.fee_cents;
    this.funding_type = json.funding_type;
  }

  isCredit(): boolean {
    return this.funding_type === 'CREDIT';
  }

  getClassName(): ClassName {
    const className: ClassName = 'Invoice';
    return className;
  }

  static getTableName(): TableName {
    return 'invoices';
  }
}

export default Invoice;
