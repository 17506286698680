import { InvoiceJSON, ReservationJSON } from '../types/json_types';
import Invoice from '../models/Invoice';
import Reservation from '../models/Reservation';
import { UpdateDeparturePageState, UpdateRoomPageState } from '../types/state_types';
import { then } from '../util/util';
import { departureDateStrForSchool } from '../util/app_util';

export const processInvoiceJSON = (invoice_json: InvoiceJSON, res_json: ReservationJSON): Invoice => {
  const res = new Reservation(res_json);

  const invoice = new Invoice(invoice_json, res);

  return invoice;
};

export const getStateForRoomUpdate = (token: string, i: Invoice | null): UpdateRoomPageState => {
  const res = i?.reservation;

  return {
    success: false,
    working: false,
    invoice_token: token,
    invoice: i || null,
    building_id: res?.building_id || null,
    room_number: res?.room_number || null,
    room_wing: res?.room_wing || null,
  };
};

export const getStateForDepartureUpdate = (token: string, i: Invoice | null): UpdateDeparturePageState => {
  const dateStr = then(i?.reservation, (r): string => {
    if (r && r.departure_date) {
      let d = r.departure_date.replace('2021', '2023')
      d = r.departure_date.replace('2022', '2023')
      return d;
    } else if (r) {
      return departureDateStrForSchool(r.school_id);
    } else {
      return '2023-04-15';
    }
  });

  return {
    success: false,
    working: false,
    invoice_token: token,
    invoice: i || null,
    departure_date: dateStr,
  };
};
